import {
  useFormSection,
  useFormFieldGroup,
  // useTextField,
  useField
} from "@/components/FormBuilder/Helpers";
import { isAfter /*isBefore, subDays*/ } from "date-fns";
// import Vue from "vue";
import { FormBlock } from "../../components/FormBuilder/types";
// const { __getText } = Vue.prototype;
function lossQuestions(viewType?: string): FormBlock[] {
  return [
    ...useFormSection(
      [
        ...useFormFieldGroup(
          [
            useField({
              key: "lossFormOtherInfo.Info.MortgageVerifiedQtn",
              label: "Has mortgage been verified with insured?",
              preamble: "Has mortgage been verified with insured?",
              required: true,
              type: viewType === "viewing" ? "dataview" : "radio_button_group",
              options: [
                { label: "Yes", value: "Y" },
                { label: "No", value: "N" }
              ]
            })
          ],
          { layout: "full" }
        ),
        ...useFormFieldGroup(
          [
            useField({
              key: "lossFormOtherInfo.Coverages.FloodWaterRisk",
              label:
                "Has the risk been impacted by flood, surface water or tidal surge?",
              preamble:
                "Has the risk been impacted by flood, surface water or tidal surge?",
              required: true,
              type: viewType === "viewing" ? "dataview" : "radio_button_group",
              options: [
                { label: "Yes", value: "Y" },
                { label: "No", value: "N" }
              ]
            })
          ],
          { layout: "full" }
        )
      ],
      { title: "Loss Form Questions" }
    )
  ];
}

export function claimsFormSchema(
  type: string,
  viewType?: string,
  otherAuthConcact?: string,
  otherPhone?: string,
  windExcludeStatement?: string
): FormBlock[] {
  const emailValidations = otherAuthConcact
    ? [
        {
          method: "email",
          error: "invalid email"
        },
        {
          method: "custom",
          error: "Emails don't match",
          customValidator: (value: any) => {
            if (!value) return true;
            if (value !== otherPhone) {
              return false;
            } else {
              return true;
            }
          }
        }
      ]
    : [];
  const windExcludedSection = getWindExcludedSection(windExcludeStatement);
  return [
    ...useFormSection(
      [
        ...useFormFieldGroup([
          useField({
            key: "lossFormOtherInfo.FormInfo.LocalAddress",
            label: "Address",
            required: true,
            type: "text"
          })
        ]),
        ...useFormFieldGroup(
          [
            useField({
              key: "lossFormOtherInfo.FormInfo.City",
              label: "City",
              required: true,
              type: "text"
            }),
            useField({
              key: "lossFormOtherInfo.FormInfo.State",
              label: "State",
              required: true,
              type: "text"
            }),
            useField({
              key: "lossFormOtherInfo.FormInfo.ZipCode",
              label: "ZipCode",
              required: true,
              type: "text"
            })
          ],
          { layout: "3-col" }
        )
      ],
      { title: "Location Address" }
    ),
    ...useFormSection(
      [
        ...useFormFieldGroup(
          [
            useField({
              key: "lossFormOtherInfo.FormInfo.LossDate",
              label: "Date of Loss",
              required: true,
              type: viewType === "viewing" ? "dataview" : "date",
              pickerOptions: {
                disabledDate: (d: Date) => isAfter(d, new Date())
              }
            }),
            useField({
              key: "lossFormOtherInfo.FormInfo.Other",
              label: "IF 'OTHER'",
              required: true,
              type: viewType === "viewing" ? "dataview" : "text",
              conditions: {
                and: [
                  {
                    operator: "equals",
                    field: "lossFormOtherInfo.FormInfo.ClassId",
                    value: "21"
                  }
                ]
              }
            })
          ],
          { layout: "3-col" }
        ),
        ...useFormFieldGroup([
          useField({
            key: "lossFormOtherInfo.FormInfo.ClassId",
            label: "Kind of Loss",
            required: true,
            filterable: true,
            type: viewType === "viewing" ? "dataview" : "select"
          })
        ]),
        ...useFormFieldGroup(
          [
            useField({
              key: "lossFormOtherInfo.FormInfo.ReportTo",
              label: "Police or Fire Dept. Reported to",
              type: viewType === "viewing" ? "dataview" : "text"
            }),
            useField({
              key: "lossFormOtherInfo.FormInfo.Descripton",
              label: "Description of Loss",
              required: true,
              type: viewType === "viewing" ? "dataview" : "textarea"
            })
          ],
          { layout: "2-col" }
        ),
        ...useFormFieldGroup(
          [
            useField({
              key: "lossFormOtherInfo.FormInfo.ClaimAntName",
              label: "Claimant Name",
              required: true,
              type: viewType === "viewing" ? "dataview" : "text",
              conditions: {
                and: [
                  {
                    operator: "equals",
                    field: "lossFormOtherInfo.FormInfo.ClassId",
                    value: "22"
                  }
                ]
              }
            }),
            useField({
              key: "lossFormOtherInfo.FormInfo.ClaimAntPhone",
              label: "Claimant Phone Number",
              type: viewType === "viewing" ? "dataview" : "phone",
              required: false,
              conditions: {
                and: [
                  {
                    operator: "equals",
                    field: "lossFormOtherInfo.FormInfo.ClassId",
                    value: "22"
                  }
                ]
              }
            })
          ],
          { layout: "2-col" }
        ),
        ...useFormFieldGroup(
          [
            useField({
              key: "lossFormOtherInfo.FormInfo.ClaimAntAddress",
              label: "Claimant Address",
              type: viewType === "viewing" ? "dataview" : "text",
              required: true,
              conditions: {
                and: [
                  {
                    operator: "equals",
                    field: "lossFormOtherInfo.FormInfo.ClassId",
                    value: "22"
                  }
                ]
              }
            })
          ],
          { layout: "full" }
        ),
        ...useFormFieldGroup(
          [
            useField({
              key: "lossFormOtherInfo.Client.Phone",
              label: "Phone no.",
              placeholder: "",
              required: true,
              type: viewType === "viewing" ? "dataview" : "phone"
            }),
            useField({
              key: "lossFormOtherInfo.Client.BusinessPhone",
              label: "Alternate Phone no.",
              placeholder: "",
              required: false,
              type: viewType === "viewing" ? "dataview" : "phone"
            })
          ],
          { layout: "2-col" }
        ),

        ...useFormFieldGroup(
          [
            useField({
              key: "lossFormOtherInfo.Client.OtherAuthConcact",
              label: "Email Address",
              type: viewType === "viewing" ? "dataview" : "email",
              validations: emailValidations
            }),
            useField({
              key: "lossFormOtherInfo.Client.OtherPhone",
              label: "Confirm Email Address",
              type: viewType === "viewing" ? "dataview" : "email",
              validations: [
                {
                  method: "email",
                  error: "invalid email"
                },
                {
                  method: "custom",
                  error: "Emails don't match",
                  customValidator: (value: any) => {
                    if (!otherAuthConcact) return true;
                    if (value !== otherAuthConcact) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                }
              ],
              conditions: {
                and: [
                  {
                    operator: "exists",
                    field: "lossFormOtherInfo.Client.OtherAuthConcact",
                    value: true
                  }
                ]
              }
            })
          ],
          { layout: "2-col" }
        ),
        ...useFormFieldGroup([
          useField({
            key: "lossFormOtherInfo.Client.NotesComments",
            label: "Additional Remarks",
            type: viewType === "viewing" ? "dataview" : "textarea"
          })
        ]),
        ...useFormFieldGroup(
          [
            useField({
              key: "lossFormOtherInfo.Info.AgContacts",
              label: "Submitted By",
              required: true,
              type: viewType === "viewing" ? "dataview" : "text"
            }),
            useField({
              key: "lossFormOtherInfo.Info.Email",
              label: "Agent Email Address",
              type: viewType === "viewing" ? "dataview" : "email",
              required: true,
              validations: [
                {
                  method: "email",
                  error: "invalid email"
                }
              ]
            }),
            useField({
              key: "lossFormOtherInfo.Coverages.FormType",
              label: "Form Type",
              type: viewType === "viewing" ? "dataview" : "text",
              readonly: true
            })
          ],
          { layout: "3-col" }
        )
      ],
      { title: "Loss Information" }
    ),
    ...lossQuestions(viewType),
    ...windExcludedSection,
    ...useFormSection([
      ...useFormFieldGroup(
        [
          useField({
            key: "lossFormOtherInfo.Coverages.DateAssigned",
            label: "Date Assigned",
            required: type !== "new" ? true : false,
            type: viewType === "viewing" ? "dataview" : "date",
            pickerOptions: {
              disabledDate: (d: Date) => isAfter(d, new Date())
            },
            conditions: {
              or: [
                {
                  operator: "equals",
                  field: "role",
                  value: "admin"
                },
                {
                  operator: "equals",
                  field: "role",
                  value: "underwriting"
                },
                {
                  operator: "equals",
                  field: "role",
                  value: "claims"
                },
                {
                  operator: "equals",
                  field: "role",
                  value: "billing"
                },
                {
                  operator: "equals",
                  field: "role",
                  value: "dataEntry"
                }
              ]
            }
          }),
          useField({
            key: "lossFormOtherInfo.Coverages.FieldAssigned",
            label: "Field Adjuster",
            required: type !== "new" ? true : false,
            type: viewType === "viewing" ? "dataview" : "select",
            conditions: {
              or: [
                {
                  operator: "equals",
                  field: "role",
                  value: "admin"
                },
                {
                  operator: "equals",
                  field: "role",
                  value: "underwriting"
                },
                {
                  operator: "equals",
                  field: "role",
                  value: "claims"
                },
                {
                  operator: "equals",
                  field: "role",
                  value: "billing"
                },
                {
                  operator: "equals",
                  field: "role",
                  value: "dataEntry"
                }
              ]
            }
          }),

          useField({
            key: "lossFormOtherInfo.Coverages.ClaimRepresentative",
            label: "Claims Representative",
            type: viewType === "viewing" ? "dataview" : "select",
            required: type !== "new" ? true : false,
            conditions: {
              or: [
                {
                  operator: "equals",
                  field: "role",
                  value: "admin"
                },
                {
                  operator: "equals",
                  field: "role",
                  value: "underwriting"
                },
                {
                  operator: "equals",
                  field: "role",
                  value: "claims"
                },
                {
                  operator: "equals",
                  field: "role",
                  value: "billing"
                },
                {
                  operator: "equals",
                  field: "role",
                  value: "dataEntry"
                }
              ]
            }
          })
        ],
        { layout: "3-col" }
      )
    ])
  ];
}

function getWindExcludedSection(windExcludeStatement?: string): any[] {
  let windExcludedSection: any[] = [];
  if (windExcludeStatement) {
    const descriptionBlock = [
      {
        element: "html",
        tagName: "p",
        innerText: windExcludeStatement,
        classList: ["mb-20", "font-bold", "text-red-500"]
      }
    ];
    windExcludedSection = descriptionBlock;
  }
  return windExcludedSection;
}
